<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="type=='view'?'培训计划查看':type=='add'?'培训计划新增':'培训计划编辑'"
                 @head-save="headSave"
                 @head-save-back="headSave(true)"
                 @head-submit="headSubmit"
                 @head-cancel="headCancel"></head-layout>
    <div class="newZtFormBox" style="background-color: white; padding-top:10px;padding-right: 10px;">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="计划年度" prop="annualPlan">
              <el-date-picker v-model="form.annualPlan" :disabled="readonly"
                              type="year" placeholder="选择年"
                              @change="changeymonth" value-format="yyyy" format="yyyy">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label='培训计划名称' prop="trainMessage">
              <el-input v-model="form.trainMessage" :disabled="readonly" placeholder='培训计划名称' maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预算总金额" prop="budgetAmount">
              <el-input v-model="form.budgetAmount" :disabled="readonly" placeholder="自行计算行信息金额" autocomplete="off"
                        readonly
                        :controls="false" style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row>

          <el-col :span="8">

            <el-form-item label='申请人' prop="applicantPerson">
              <el-input v-model="form.applicantPerson" :disabled="readonly" placeholder="默认当前登陆人" autocomplete="off"
                        readonly
                        :controls="false" style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">

            <el-form-item label='培训组织' prop="reportDept">

              <el-input v-model="form.reportDept" :disabled="readonly" placeholder="默认当前登陆人组织" readonly
                        style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="编制时间" prop="preparationTime">
              <el-date-picker v-model="form.preparationTime" disabled type="date" placeholder="默认当前时间"
                              @change="changeymonth" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label='说明' prop="remark">
              <el-input v-model="form.remark" :disabled="readonly" placeholder='说明' type="textarea"
                        maxlength="300" show-word-limit :rows="3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


      </el-form>
    </div>
    <head-layout
      :head-btn-options="subTableheadBtnOptions"
      head-title="培训明细"
      @head-remove="headRemove"
      @head-add-tabs="subTableAdd"
    ></head-layout>
    <grid-layout
      ref="crud"
      class="serviceType"
      :grid-row-btn="gridRowBtn"
      :table-options="subTableOption"
      :table-data="data"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @gird-handle-select-click="selectionChange"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-view="treeMenuEdit"
      @row-remove="rowRemove"
      @row-edit="treeMenuEdit">

    </grid-layout>
    <CommonDialog v-if="showTreeDialog" width="60%" :showConfirmBtn="!readonly" dialogTitle="培训明细" @cancel="showTreeDialog = false" @confirm="handleTreeNodeSave">
      <avue-form :option="treeDialogOption"
                 ref="addForm"
                 v-model="formData"
                 @submit="treeNodeSave"></avue-form>
    </CommonDialog>
  </div>

</template>

<script>

import FormLayout from "@/views/components/layout/form-layout";
import {
  dictionaryBiz,
  SaveTrainingPlan,
  GettrainingDetail,
  SaveTrainingUpdate,
  getOrganization,
  trainingSonDelete,
  trainingSonSave,
  useTrainingId, trainingPlanIdUpdate, trainingPlanStatusUpdate
} from "@/api/safe/index";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";

import {getUserInfo} from "@/api/system/user";
import sinoma from "@/util/sinoma";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";
import {getLazyList} from "../../../../../api/system/dept";
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "dataCoonfigAdd",
  mixins: [exForm, draft],
  computed: {
    ...mapGetters(['userInfo']),
    headBtnOptions() {
      let buttonBtn = [];
      if (["add", "edit", "workflow"].includes(this.type) && (this.form.status == 1 || this.form.status == 4 || this.form.status == -1)) {
        buttonBtn.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        });
      }
      if (["add", "edit"].includes(this.type) && (this.form.status == 1 || this.form.status == -1)) {
        buttonBtn.push({
          label: "保存并返回",
          emit: "head-save-back",
          type: "button",
          icon: "",
          btnOptType: "saveBack",
        });
      }
      //草稿状态可以提交
      if (this.form.id && (this.form.status == 1 || this.form.status == -1) && ["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "提交",
          emit: "head-submit",
          type: "button",
          icon: "",
          btnOptType: "submit",
        });
      }
      if (this.type != "workflow") {
        buttonBtn.push({
          label: "取消",
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: "cancel",
        });
      }
      return buttonBtn;
    },
    subTableheadBtnOptions() {
      let buttonBtn = [];
      if (["add", "edit", "workflow"].includes(this.type) && (this.form.status == 1 || this.form.status == 4 || this.form.status == -1)) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
      }
      if (["add", "edit", "workflow"].includes(this.type) && (this.form.status == 1 || this.form.status == 4 || this.form.status == -1)) {
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }
      return buttonBtn
    },
    gridRowBtn() {
      let buttonBtn = [];
      if (["add", "edit", "workflow"].includes(this.type) && (this.form.status == 1 || this.form.status == 4 || this.form.status == -1)) {
        buttonBtn.push({
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (["add", "edit", "workflow"].includes(this.type) && (this.form.status == 1 || this.form.status == 4 || this.form.status == -1)) {
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (["view", "workflow"].includes(this.type)) {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [{
          label: "拟组织培训项目",
          prop: 'trainingProject',
          cell: true,
          disabled: this.readonly,
          maxlength: 50,
          rules: [
            {
              required: true,
              message: "请输入拟组织培训项目",
              trigger: 'blur'
            }
          ]
        },
          {
            label: "培训类型",
            type: "select",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=train_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            width: 120,
            prop: "trainingType",
            slot: true,
            disabled: this.readonly,
            rules: [{
              required: true,
              message: "请选择培训类型",
              trigger: "blur"
            }]
          },
          {
            label: '培训月份',
            prop: "trainingMonth",
            type: 'select',
            placeholder: '请选择培训月份',
            dicUrl: `/api/sinoma-system/dict/dictionary?code=month_options`,
            disabled: this.readonly,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            rules: [{
              required: true,
              message: "请选择培训月份",
              trigger: "blur"
            }]
          },
          {
            label: "计划参训人数",
            prop: 'numberTrainees',
            cell: true,
            disabled: this.readonly,
            rules: [
              {
                required: true,
                message: "请输入计划参训人数",
                trigger: "blur"
              },
              {
                pattern: /^[1-9][0-9]*$/,
                message: "请输入正整数",
              }
            ],
          },
          {
            label: "是否自行组织",
            prop: 'selfOrganization',
            type: 'radio',
            disabled: this.readonly,
            value: "1",
            dicData: [
              {
                label: "是",
                value: "1"
              },
              {
                label: "否",
                value: "0"
              }
            ],
            rules: [
              {
                required: true,
                message: "请选择是否自行组织",
                trigger: 'blur'
              }
            ]
          },
          {
            label: "合作单位",
            prop: 'cooperativeUnit',
            disabled: this.readonly,
            cell: true
          },

          {
            label: "培训预算",
            append: '元',
            prop: 'trainingBudget',
            cell: true,
            disabled: this.readonly,
            rules: [
              {
                required: true,
                message: "请输入培训预算",
                trigger: 'blur'
              },
              {
                pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/,
                message: "请输入正数(末尾保留1~2位小数)",
              }
            ]
          },
          {
            label: "说明",
            prop: 'remark',
            cell: true,
            disabled: this.readonly,
            type: "textarea",
            overHidden: true,
            maxlength: 200,
            span: 24,
            showWordLimit: true,
          },
        ]
      }
    },
  },
  data() {
    return {
      type: "",
      data: [],
      /**
       * 工作流信息
       * **/
      processId: '',
      processInstanceId: '',
      formProcess: {
        id: '',
        processId: '',
        data: {},
      },
      componylist: [],
      trainTypeList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showTreeDialog: false,
      alist: [],
      activeName: "1",
      tableLoading: false,
      subTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: 'id',
        rowParentKey: 'parentId',
        menuWidth: 120,
        column: [{
          label: "拟组织培训项目",
          prop: 'trainingProject',
          cell: true,
        },
          {
            label: "培训类型",
            prop: 'trainingType',
            type: "select",
            cell: true,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=train_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
          },
          {
            label: "培训月份",
            prop: 'trainingMonth',
            cell: true
          },
          {
            label: "预计培训人数",
            prop: 'numberTrainees',
            cell: true
          },
          {
            label: "合作单位",
            prop: 'cooperativeUnit',
            cell: true
          },

          {
            label: "培训预算",
            prop: 'trainingBudget',
            cell: true
          },
          {
            label: "说明",
            prop: 'remark',
          },
        ]
      },

      rules: {
        annualPlan: [
          {
            required: true,
            message: '选择年',
            trigger: "blur",
          },
        ],
        trainMessage: [
          {
            required: true,
            message: '请输入培训计划名称',
            trigger: "blur",
          }
        ],
        preparationTime: [
          {
            required: true,
            message: '请选择编制时间',
            trigger: "blur",
          }
        ]
      },
      formData: {
        trainingId: ""
      },
      form: {
        id: '',
        processId: '',
        annualPlan: "",
        trainMessage: "",
        budgetAmount: "",
        applicantPerson: "",
        reportDept: "",
        preparationTime: "",
        status: 1, //状态草稿
        remark: '' //说明
      },
    };
  },
  components: {
    FormLayout,
    GridLayout,
    HeadLayout,
    CommonDialog
  },
  created() {
    console.log(1111)
    let url = window.location.href;
    let p = url.split("?")[1];
    let id = "";
    let type = "view";
    if (p) {
      let query = new URLSearchParams(p);
      id = query.get("id");
      type = query.get("type");
    } else {
      id = this.$route.query.id;
      type = this.$route.query.type;
    }
    this.type = type;
    if (["view", "workflow"].includes(this.type)) {
      this.readonly = true;
    } else {
      this.readonly = false;
    }
    const today = new Date(); // 获取当前日期
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.form.preparationTime = `${year}-${month}-${day}`; // 将日期转换为字符串格式并设置到 this.form.preparationTime
    if (this.$route.query.type != 'add') return
    getUserInfo().then(res => {
      this.form.reportDept = res.data.data.deptName
      try {
        this.form.applicantPerson = JSON.parse(res.data.data.realName).zh
      } catch (e) {
        this.form.applicantPerson = res.data.data.realName
      }
    })
    this.form.annualPlan = year.toString()
  },
  mounted() {
    this.getDictory()
    if (this.$route.query.id || this.form.id) {
      this.MGettrainingDetail()
    }
  },
  watch: {
    data: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        if (newValue.length > 0) {
          // eslint-disable-next-line no-unused-vars,no-undef
          let count = newValue.map(e => e.trainingBudget).reduce((i, j) => {
            return Number(i) + Number(j)
          })
          this.form.budgetAmount = count;
        }
      }
    }
  },
  methods: {
    subTableAdd() {
      if (!this.form.id) {
        this.$message.warning("请先保存头数据")
        return
      }
      this.formData = {}
      this.showTreeDialog = true
    },
    headRemove() {
      if (this.selectionList.length == 0) {
        this.$message.warning("cip.cmn.msg.warning.selectWarning")
        return false
      }
      var ids = this.selectionList.map(item => item.id);
      trainingSonDelete({id: ids.join(',')}).then((res) => {
        this.data = res.data.data
        if (res.data.code === 200) {
          this.MGettrainingDetail()
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        } else {
          this.$message.success(res.data.msg);
        }

      });
    },
    getProcessId(page, params = {}) {
      this.submitLoading = true;
      this.page = page;
      getProcessList(
        page.currentPage,
        page.pageSize,
        params
      ).then(res => {
        let process = res.data.data.records.filter((item) => item.key == this.processTemplateKey)[0]
        this.processId = process.id
        this.getForm(this.processId)
        this.tag.label = process.name
      });
    },

    selectionChange(list) {
      this.selectionList = list;
    },
    treeNodeSave(data, done) {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (!this.form.parentId) this.form.parentId = this.dataForm.id
          trainingSonSave(this.form).then(res => {
            const {msg, data} = res.data;
            this.treeReadOnlyFlag = true;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            this.showTreeDialog = false;
          }).catch(err => {
            console.log(err)
            done()
          })
        }
      })
    },

    handleTreeNodeSave() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.formData.trainingId = this.$route.query.id || this.form.id
          trainingSonSave(this.formData).then(res => {
            const {msg, data} = res.data;
            this.treeReadOnlyFlag = true;
            if (res.data.code == 200 && msg!="同种类型的培训每月只能有一次") {
              this.formData = data;
              useTrainingId({id: this.$route.query.id || this.form.id}).then((res1) => {
                this.data = res1.data.data;
                this.$nextTick(() => {
                  this.headSave(false)
                })
                // if (res1.data.code == 200) {
                //   this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                // } else {
                //   this.$message.success(msg);
                // }
              });
              this.showTreeDialog = false;
            } else {
              this.$refs.addForm.allDisabled = false
              this.$message.warning(msg);
            }
          }).catch((err) =>{
            this.$refs.addForm.allDisabled = false
            this.$message.error(err);
          })
        }
      })
    },
    changeymonth(e) {
      console.log(e)
      let index = e.lastIndexOf("-")
      this.form.year = e.substring(0, index);
      this.form.month = e.substring(index + 1, e.length);
    },
    // 获取数据字典
    getDictory() {
      dictionaryBiz("trainType").then((res) => {
        this.trainTypeList = res.data.data;
      });
    },

    MGettrainingDetail() {
      GettrainingDetail({id: this.$route.query.id || this.form.id}).then((res) => {
        if (["view", "workflow"].includes(this.type)) {
          this.readonly = !(["workflow"].includes(this.type) && res.data.data.status == 4);
        } else {
          this.readonly = false;
        }
        this.form = res.data.data;
        // getLazyList(1, 10).then((res) => {
        //   this.componylist = res.data.data.records;
        //   var abb = JSON.parse(JSON.stringify(this.componylist));
        //   var alltree = this.flatten(abb);
        //   var pid = ''
        //   alltree.forEach(element => {
        //     if (this.form.deptId == element.id) {
        //       pid = element.parentId
        //     }
        //   });
        //   var cc = this.FinTfather(alltree, pid).reverse();
        //   cc.push(this.form.deptId)
        //   this.form.deptId = cc
        //
        // });
        useTrainingId({id: this.$route.query.id || this.form.id}).then((res) => {
          if (res.data.code == 200) {
            this.data = res.data.data;
          } else {
            this.$message.success(res.data.msg);
          }
        });
      });
    },
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.formData = data;
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return trainingSonDelete({id: row.id});
        })
        .then(() => {
          this.MGettrainingDetail();
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },

    headSave(cancel = false) {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$loading();
          if (this.$route.query.id || this.form.id) {
            let parms = {
              id: this.form.id,
              annualPlan: this.form.annualPlan,
              budgetAmount: this.form.budgetAmount,
              applicantPerson: this.form.applicantPerson,
              reportDept: this.form.reportDept,
              preparationTime: this.form.preparationTime,
              trainMessage: this.form.trainMessage,
              remark: this.form.remark
            }

            try {
              getOrganization(parms).then(res => {
                if(res.data !=""){
                  this.$message.error("当前组织已存在"+
                    parms.annualPlan+"年度计划，请勿重复操作");
                }else {
                  SaveTrainingUpdate(parms).then(res => {
                    this.form = res.data.data
                    this.$message.success(this.$t('cip.dc.offlineDComission.savemsg'));
                    // this.headCancel()
                  })
                }
              })
            } catch (e) {

            }
          } else {
            let parms = {
              annualPlan: this.form.annualPlan,
              budgetAmount: this.form.budgetAmount,
              applicantPerson: this.form.applicantPerson,
              reportDept: this.form.reportDept,
              preparationTime: sinoma.toDate(new Date()),
              trainMessage: this.form.trainMessage,
              remark: this.form.remark
            }
              getOrganization(parms).then(res => {
                if(res.data !=""){
                  this.$message.error("当前组织已存在"+
                    parms.annualPlan+"年度计划，请勿重复操作");
                }else {
                  SaveTrainingPlan(parms).then(res => {
                    this.form = res.data.data
                    this.$message.success(this.$t('cip.dc.offlineDComission.savemsg'))
                    // this.headCancel()
                  })
                }
              })
          }
          this.$loading().close();
          if (cancel) {
            this.headCancel()
          }
        } else {
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headSubmit() {
      if (this.data.length == 0) {
        this.$message.warning('请添加培训明细后,再提交!')
        return
      }
      this.submitLoading = true;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$loading();
          if (this.form.id) {
            trainingPlanStatusUpdate({id: this.form.id, status: 2}).then(
              (res) => {
                if (res.data.code == 200) {
                  this.$message({
                    type: "success",
                    message: "提交成功",
                  });
                  this.formProcess.id = res.data.data
                  this.formProcess.data = {...this.form}
                  this.formProcess.deptId = this.userInfo.dept_id
                  // this.formProcess.deptId = this.userInfo.dept_id
                  this.formProcess.processBusinessName = this.form.trainMessage
                  this.formProcess.taskName  = "培训计划流程|"+this.form.trainMessage+"|"+this.userInfo.nick_name
                  this.formProcess.typeName = '教育培训';
                  this.formProcess.processDefKey = 'iframe_training_plan_wf';
                  this.handleStartProcess5(true).then(done => {
                    // 更新业务数据流程任务id
                    trainingPlanIdUpdate({taskId: done.data.data, id: res.data.data}).then(resUp => {
                      this.$router.$avueRouter.closeTag();
                      this.$router.push(
                        "/business/safetyenment/train/plan"
                      );
                    })
                    done()
                  })
                }
              }
            ).finally(() => {
              this.$loading().close();
            })
          }
        }
      });
    },

    //扁平化 树
    flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = []
          result = result.concat(arr[i])
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },

    //寻找父级
    FinTfather(arr, fid) {
      let arr2 = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id == fid) {
          if (arr[i].parentId != "0") {
            fid = arr[i].parentId;
            arr2.push(arr[i].id);
            this.FinTfather(arr, fid);
          } else {
            arr2.push(arr[i].id);
          }
        }
      }
      return arr2;
    },


  },
};
</script>

<style lang="scss" scoped>
::v-deep .avue-crud .el-table {
  height: calc(100vh - 423px) !important;
  max-height: calc(100vh - 423px) !important;
}
</style>
