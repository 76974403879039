var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            _vm.type == "view"
              ? "培训计划查看"
              : _vm.type == "add"
              ? "培训计划新增"
              : "培训计划编辑",
        },
        on: {
          "head-save": _vm.headSave,
          "head-save-back": function ($event) {
            return _vm.headSave(true)
          },
          "head-submit": _vm.headSubmit,
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        {
          staticClass: "newZtFormBox",
          staticStyle: {
            "background-color": "white",
            "padding-top": "10px",
            "padding-right": "10px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "计划年度", prop: "annualPlan" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              disabled: _vm.readonly,
                              type: "year",
                              placeholder: "选择年",
                              "value-format": "yyyy",
                              format: "yyyy",
                            },
                            on: { change: _vm.changeymonth },
                            model: {
                              value: _vm.form.annualPlan,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "annualPlan", $$v)
                              },
                              expression: "form.annualPlan",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "培训计划名称",
                            prop: "trainMessage",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.readonly,
                              placeholder: "培训计划名称",
                              maxlength: "50",
                            },
                            model: {
                              value: _vm.form.trainMessage,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "trainMessage", $$v)
                              },
                              expression: "form.trainMessage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "预算总金额", prop: "budgetAmount" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.readonly,
                              placeholder: "自行计算行信息金额",
                              autocomplete: "off",
                              readonly: "",
                              controls: false,
                            },
                            model: {
                              value: _vm.form.budgetAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "budgetAmount", $$v)
                              },
                              expression: "form.budgetAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请人", prop: "applicantPerson" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.readonly,
                              placeholder: "默认当前登陆人",
                              autocomplete: "off",
                              readonly: "",
                              controls: false,
                            },
                            model: {
                              value: _vm.form.applicantPerson,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "applicantPerson", $$v)
                              },
                              expression: "form.applicantPerson",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "培训组织", prop: "reportDept" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.readonly,
                              placeholder: "默认当前登陆人组织",
                              readonly: "",
                            },
                            model: {
                              value: _vm.form.reportDept,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "reportDept", $$v)
                              },
                              expression: "form.reportDept",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "编制时间", prop: "preparationTime" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              disabled: "",
                              type: "date",
                              placeholder: "默认当前时间",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: { change: _vm.changeymonth },
                            model: {
                              value: _vm.form.preparationTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "preparationTime", $$v)
                              },
                              expression: "form.preparationTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "说明", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.readonly,
                              placeholder: "说明",
                              type: "textarea",
                              maxlength: "300",
                              "show-word-limit": "",
                              rows: 3,
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.subTableheadBtnOptions,
          "head-title": "培训明细",
        },
        on: { "head-remove": _vm.headRemove, "head-add-tabs": _vm.subTableAdd },
      }),
      _c("grid-layout", {
        ref: "crud",
        staticClass: "serviceType",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.subTableOption,
          "table-data": _vm.data,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-view": _vm.treeMenuEdit,
          "row-remove": _vm.rowRemove,
          "row-edit": _vm.treeMenuEdit,
        },
      }),
      _vm.showTreeDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                width: "60%",
                showConfirmBtn: !_vm.readonly,
                dialogTitle: "培训明细",
              },
              on: {
                cancel: function ($event) {
                  _vm.showTreeDialog = false
                },
                confirm: _vm.handleTreeNodeSave,
              },
            },
            [
              _c("avue-form", {
                ref: "addForm",
                attrs: { option: _vm.treeDialogOption },
                on: { submit: _vm.treeNodeSave },
                model: {
                  value: _vm.formData,
                  callback: function ($$v) {
                    _vm.formData = $$v
                  },
                  expression: "formData",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }